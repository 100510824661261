import { Component, Input } from '@angular/core';

@Component({
  selector: 'rwo-icon-link',
  templateUrl: './icon-link.component.html',
  styleUrls: ['./icon-link.component.scss'],
})
export class IconLinkComponent {
  @Input() href: string;
  @Input() icon: string;
}
