import { Component } from '@angular/core';

interface PlatformItem {
  name: string;
  href: string;
  imageFilename: string;
}

interface CustomerImage {
  imageFilename: string;
  contrastWithWhite: boolean;
  title: string;
  height: number;
}

interface Post {
  url: string;
  title: string;
  icon: string;
  date: Date;
  subline: string;
}

@Component({
  selector: 'rwo-media',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.scss'],
})
export class MediaComponent {
  platformItems: PlatformItem[] = [
    {
      name: 'XING',
      href: 'https://www.xing.com/profile/Richard_Wotzlaw',
      imageFilename: 'xing.png',
    },
    {
      name: 'LinkedIn',
      href: 'https://www.linkedin.com/in/richard-wotzlaw-8653b688/',
      imageFilename: 'linkedin.png',
    },
    {
      name: 'GULP',
      href: 'https://www.gulp.de/gulp2/g/spezialisten/profil/richard.wotzlaw',
      imageFilename: 'gulp.jpg',
    },
    {
      name: 'Github',
      href: 'https://github.com/gannimet',
      imageFilename: 'github.png',
    },
    {
      name: 'Stack Overflow',
      href: 'https://stackoverflow.com/users/1722704/richard',
      imageFilename: 'stackoverflow.svg',
    },
    {
      name: 'Medium',
      href: 'https://gannimet.medium.com/',
      imageFilename: 'medium.svg',
    },
  ];

  customersImages: CustomerImage[] = [
    {
      imageFilename: 'gls.svg',
      contrastWithWhite: true,
      title: 'GLS',
      height: 120,
    },
    {
      imageFilename: 'ey.svg',
      contrastWithWhite: true,
      title: 'Ernst & Young',
      height: 150,
    },
    {
      imageFilename: 'senacor.svg',
      contrastWithWhite: true,
      title: 'Senacor Technologies AG',
      height: 60,
    },
    {
      imageFilename: 'cgm.webp',
      contrastWithWhite: true,
      title: 'CompuGroup Medical Deutschland AG',
      height: 150,
    },
    {
      imageFilename: '365farmnet.png',
      contrastWithWhite: false,
      title: '365FarmNet GmbH',
      height: 80,
    },
    {
      imageFilename: 'airberlin.svg',
      contrastWithWhite: true,
      title: 'Air Berlin PLC & Co. Luftverkehrs KG i.I.',
      height: 80,
    },
    { imageFilename: 'kiwigrid.png', contrastWithWhite: true, title: 'Kiwigrid GmbH', height: 70 },
    { imageFilename: 'coyo.svg', contrastWithWhite: true, title: 'COYO GmbH', height: 70 },
  ];

  posts: Post[] = [
    {
      title: 'Build an analog clock with HTML, CSS and JS',
      url: 'https://www.youtube.com/watch?v=K_6SUFwimiU',
      date: new Date(2023, 0, 18),
      icon: 'youtube',
      subline: 'Veröffentlicht auf youtube.com',
    },
    {
      title: 'Tic Tac Toe with React.js',
      url: 'https://www.youtube.com/watch?v=dSNxfecgrZc',
      date: new Date(2022, 6, 6),
      icon: 'youtube',
      subline: 'Veröffentlicht auf youtube.com',
    },
    {
      title: '5 ways to vertically center stuff in CSS',
      url: 'https://www.youtube.com/watch?v=Kcibahl5a-U&t=220s',
      date: new Date(2022, 4, 9),
      icon: 'youtube',
      subline: 'Veröffentlicht auf youtube.com',
    },
    {
      title: 'Angular Reactive Form in a Nutshell',
      url: 'https://www.youtube.com/watch?v=omgRcz2Hsr0',
      date: new Date(2022, 3, 28),
      icon: 'youtube',
      subline: 'Veröffentlicht auf youtube.com',
    },
    {
      title: 'Migrating a large Angular project from Jasmine to Jest incrementally',
      url: 'https://gannimet.medium.com/migrating-a-large-angular-project-from-jasmine-to-jest-incrementally-52cfd846333b',
      date: new Date(2020, 10, 28),
      icon: 'medium',
      subline: 'Veröffentlicht auf medium.com',
    },
  ];
}
