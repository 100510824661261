<div class="post-embed">
  <mat-icon class="post-embed__icon" [svgIcon]="icon"></mat-icon>
  <div class="post-embed__info">
    <div class="post-embed__info__title">
      <a [href]="href">{{ title }}</a>
    </div>
    <div class="post-embed__info__meta">
      <span class="post-embed__info__meta__published">{{ subline }}</span>
      <span class="post-embed__info__meta__separator">·</span>
      <span class="post-embed__info__meta__date">{{ date | date: 'dd.MMMM YYYY' }}</span>
    </div>
  </div>
</div>
