import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { pages } from './model/site-map';

const routes: Routes = pages.map((page) => {
  return {
    path: page.path,
    component: page.component,
  };
});

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
