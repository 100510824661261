import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component } from '@angular/core';
import { pages } from '../../model/site-map';

@Component({
  selector: 'rwo-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  animations: [
    trigger('expandAndCollapse', [
      state(
        'expanded',
        style({
          height: '*',
        }),
      ),

      transition(':enter', [
        style({
          height: 0,
        }),
        animate('0.2s ease-out'),
      ]),

      transition(':leave', [
        animate(
          '0.2s ease-in',
          style({
            height: 0,
          }),
        ),
      ]),
    ]),
  ],
})
export class MenuComponent {
  pages = pages.sort((a, b) => {
    return a.order - b.order;
  });
  isMobileMenuShown = false;
}
