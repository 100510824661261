<h1>Profil</h1>

<p>
  Laden Sie sich gerne mein aktuelles
  <rwo-icon-link href="/assets/downloads/resume_de.pdf" icon="pdf">Profil als PDF</rwo-icon-link>
  oder
  <rwo-icon-link href="/assets/download/resume_de.docx" icon="docx"
    >als Word-Dokument</rwo-icon-link
  >
  herunter.
</p>
