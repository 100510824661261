<h1>Kontakt</h1>

<p>
  Sie haben Interesse an einer Zusammenarbeit oder eine Frage zu meinem Leistungsumfang?
  Kontaktieren Sie mich gerne per E-Mail:
</p>

<div class="email-link-container">
  <mat-icon svgIcon="email"></mat-icon>
  <a href="mailto:info@colimit.de">info@colimit.de</a>
</div>
