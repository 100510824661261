import { Component, Input } from '@angular/core';

@Component({
  selector: 'rwo-post-embed',
  templateUrl: './post-embed.component.html',
  styleUrls: ['./post-embed.component.scss'],
})
export class PostEmbedComponent {
  @Input() title: string;
  @Input() icon: string;
  @Input() href: string;
  @Input() date: Date;
  @Input() subline: string;
}
