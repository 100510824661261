import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { EtengoBadgeComponent } from './elements/etengo-badge/etengo-badge.component';
import { FooterComponent } from './elements/footer/footer.component';
import { IconLinkComponent } from './elements/icon-link/icon-link.component';
import { MenuComponent } from './elements/menu/menu.component';
import { PostEmbedComponent } from './elements/post-embed/post-embed.component';
import { ContactComponent } from './pages/contact/contact.component';
import { HomeComponent } from './pages/home/home.component';
import { ImprintComponent } from './pages/imprint/imprint.component';
import { MediaComponent } from './pages/media/media.component';
import { ProfileComponent } from './pages/profile/profile.component';

@NgModule({
  declarations: [
    AppComponent,
    ProfileComponent,
    HomeComponent,
    MenuComponent,
    FooterComponent,
    ImprintComponent,
    ContactComponent,
    MediaComponent,
    IconLinkComponent,
    PostEmbedComponent,
    EtengoBadgeComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatIconModule,
    HttpClientModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
