<header>
  <nav class="nav nav__desktop">
    <ng-container
      [ngTemplateOutlet]="menuList"
      [ngTemplateOutletContext]="{ isMobile: false }"
    ></ng-container>
  </nav>

  <nav class="nav nav__mobile">
    <div
      class="burger-menu"
      [class.opened]="isMobileMenuShown"
      (click)="isMobileMenuShown = !isMobileMenuShown"
    >
      <span class="burger-menu__line">&nbsp;</span>
      <span class="burger-menu__line">&nbsp;</span>
      <span class="burger-menu__line">&nbsp;</span>
    </div>

    <ng-container
      [ngTemplateOutlet]="menuList"
      [ngTemplateOutletContext]="{ isMobile: true }"
    ></ng-container>
  </nav>
</header>

<ng-template #menuList let-mobile="isMobile">
  <ul class="nav-menu" *ngIf="!mobile || isMobileMenuShown" [@expandAndCollapse]="'expanded'">
    <ng-container *ngFor="let page of pages; let isLast = last">
      <li *ngIf="page.showInMenu" class="nav-menu__item">
        <a
          [class]="page.class"
          [class.last-link]="isLast"
          [routerLink]="page.path"
          (click)="isMobileMenuShown = false"
          >{{ page.name }}</a
        >
      </li>
    </ng-container>
  </ul>
</ng-template>
