<div class="tagline">
  <mat-icon svgIcon="coding"></mat-icon>
  <div>
    Ich bin freiberuflicher Softwareentwickler und helfe Ihnen bei der Umsetzung Ihrer IT-Projekte.
  </div>
</div>

<hr />

<h2>Leistungen:</h2>

<div class="services-container">
  <ul class="services-list">
    <li>Agile Softwareentwicklung</li>
    <li>moderne Frontend-Entwicklung mit Typescript/Javascript</li>
    <li>Gängige Frontend-Frameworks wie Angular und React</li>
    <li>State Management & Clean Code</li>
    <li>SQL- und NoSQL-Datenbanken</li>
    <li>Ein Auge für Design und Nutzerwünsche</li>
  </ul>

  <rwo-etengo-badge></rwo-etengo-badge>
</div>
