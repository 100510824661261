<h1>Referenzen</h1>

<p>Sie finden mich auf folgenden Plattformen:</p>

<ul class="platforms-list">
  <li class="platforms-list__item" *ngFor="let item of platformItems">
    <a [href]="item.href" [title]="item.name"
      ><img [src]="'/assets/img/platform-icons/' + item.imageFilename" [alt]="item.name + ' logo'"
    /></a>
  </li>
</ul>

<h2>Neueste Veröffentlichungen</h2>

<rwo-post-embed
  *ngFor="let post of posts"
  [title]="post.title"
  [href]="post.url"
  [icon]="post.icon"
  [date]="post.date"
  [subline]="post.subline"
></rwo-post-embed>

<h2>Kunden</h2>

<rwo-etengo-badge></rwo-etengo-badge>

<ul class="customers-icons">
  <li *ngFor="let image of customersImages" class="customers-icons__item">
    <img
      [alt]="image.title"
      [src]="'/assets/img/customers/' + image.imageFilename"
      [class.white-contrast]="image.contrastWithWhite"
      [style.height.px]="image.height"
    />
  </li>
</ul>
