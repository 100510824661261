import { Type } from '@angular/core';
import { ContactComponent } from '../pages/contact/contact.component';
import { HomeComponent } from '../pages/home/home.component';
import { ImprintComponent } from '../pages/imprint/imprint.component';
import { MediaComponent } from '../pages/media/media.component';
import { ProfileComponent } from '../pages/profile/profile.component';

export interface Page {
  order: number;
  path: string;
  component: Type<any>;
  name: string;
  class: string;
  showInMenu: boolean;
}

export const pages: Page[] = [
  {
    order: 2,
    path: 'profile',
    component: ProfileComponent,
    name: 'Profil',
    class: 'page-profile',
    showInMenu: true,
  },
  {
    order: 3,
    path: 'media',
    component: MediaComponent,
    name: 'Referenzen',
    class: 'page-media',
    showInMenu: true,
  },
  {
    order: 4,
    path: 'contact',
    component: ContactComponent,
    name: 'Kontakt',
    class: 'page-contact',
    showInMenu: true,
  },
  {
    order: 5,
    path: 'imprint',
    component: ImprintComponent,
    name: 'Impressum',
    class: 'page-imprint',
    showInMenu: true,
  },
  {
    order: 1,
    path: '',
    component: HomeComponent,
    name: 'Home',
    class: 'page-home',
    showInMenu: true,
  },
];
